/* Base styles */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";
@import url("https://cdn.syncfusion.com/ej2/20.3.56/bootstrap5.css");

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
  height: 100%;
}

.App {
  display: flex;
}

.navbar {
  display: none;
}

.sidebar-main {
  width: 180px;
  background-color: #333;
  color: white;
}

.nav-content-wrapper {
  width: calc(100% - 180px);
  background-color: #eef2fa;
  height: 100vh;
  overflow-y: scroll;
}

.navbar {
  /* height: 50px; */
  background-color: #444;
  color: white;
  flex-grow: 1;
  /* Navbar takes remaining space */
}

.content {
  flex-grow: 3;
  /* Content takes most of the space */
  padding: 20px 28px;
  background-color: #eef2fa;
}
.flex-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Base style for flex items */
.flex-item {
  border: none;
  padding: 5px;
  text-align: left;
  font-size: 20px;
}

.item-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Define column sizes */
.col-1 { flex: 0 0 calc(8.333% - 20px); }
.col-2 { flex: 0 0 calc(16.666% - 20px); }
.col-3 { flex: 0 0 calc(25% - 20px); }
.col-4 { flex: 0 0 calc(33.333% - 20px); }
.col-5 { flex: 0 0 calc(41.666% - 20px); }
.col-6 { flex: 0 0 calc(50% - 20px); }
.col-7 { flex: 0 0 calc(58.333% - 20px); }
.col-8 { flex: 0 0 calc(66.666% - 20px); }
.col-9 { flex: 0 0 calc(75% - 20px); }
.col-10 { flex: 0 0 calc(83.333% - 20px); }
.col-11 { flex: 0 0 calc(91.666% - 20px); }
.col-12 { flex: 0 0 calc(100% - 20px); }






.dashboard_container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.dashboard_categories {
  padding: 20px;
  background-color: #fff;
  width: 40%;
}

.category_item_wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  /* width: 50%; */
}

.category_item {
  border: 1px solid #a22592;
  padding: 15px;
  min-width: 40%;
  background-color: #dfb4d9;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 5px;
  height: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
}
/* 
.sidebar-visible {
  display: block !important;
} */

#loader {
  color: #008cff;
  height: 40px;
  left: 45%;
  position: absolute;
  top: 45%;
  width: 30%;
}

.ribbonTemplate {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ribbonTemplate.Large {
  flex-direction: column;
}

.ribbonTemplate.Large .e-icons {
  font-size: 35px;
}

.ribbonTemplate.Medium .e-icons,
.ribbonTemplate.Small .e-icons {
  font-size: 20px;
  margin: 15px 5px;
}

.ribbonTemplate.Small .text {
  display: none;
}

/* Represents the styles for Ribbon group */
.font-group .e-ribbon-group-content {
  justify-content: center;
}

/* .css-13cymwt-control {
  margin-top: 0.75rem;
} */

/* Styles for screens smaller than 768px */
@media screen and (max-width: 600px) {
  .sidebar-main {
    width: 100%;
    display: none;
    position: fixed;
    z-index: 4;
  }

  .sidebar-main.viewSidebar {
    display: block;
    width: 100%;
  }
  /* Adjustments for small screens */
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6,
  .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    flex: 0 0 calc(100% - 20px);
  }
  .rotate-on-mobile {
    transform: rotate(90deg);
  }
}


@media (max-width: 900px) {
  .sidebar {
    width: 100%;
    /* Sidebar takes full width on smaller screens */
    display: block;
  }

  .navbar {
    width: 100%;
    display: block;
    /* Navbar takes full width on smaller screens */
  }

  .content {
    /* width: 100%;  */
    padding: 10px;
  }

  .sidebar-main {
    display: none;
  }

  .nav-content-wrapper {
    width: 100%;
  }

  .sidebar-visible {
    position: absolute;
    height: 100%;
    z-index: 100;
  }
  /* .sidebar-main {
     width: 100%;
     display: none;
     position: fixed;
     z-index: 4;
   }*/
  .sidebar-main.viewSidebar {
    display: block;
    width: 100%;
  }
  /* Adjustments for small screens */
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6,
  .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    flex: 0 0 calc(100% - 20px);
  }
  .rotate-on-mobile {
    transform: rotate(90deg);
  }

}


/* Media queries for responsiveness */
@media screen and (max-width: 768px) {


  .sidebar {
    width: 100%;
    /* Sidebar takes full width on smaller screens */
     display: block;
  }

  .navbar {
    width: 100%;
    display: block;
    /* Navbar takes full width on smaller screens */
  }

  .content {
    /* width: 100%;  */
    padding: 10px;
  }

  .sidebar-main {
    display: none;
  }

  .nav-content-wrapper {
    width: 100%;
  }

  .sidebar-visible {
    position: absolute;
    height: 100%;
    z-index: 100;
  }
 /* .sidebar-main {
    width: 100%;
    display: none;
    position: fixed;
    z-index: 4;
  }*/
  .sidebar-main.viewSidebar {
    display: block;
    width: 100%;
  }
  /* Adjustments for small screens */
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6,
  .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    flex: 0 0 calc(100% - 20px);
  }
  .rotate-on-mobile {
    transform: rotate(90deg);
  }
}
