.search-bar-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-input {
  flex-grow: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  height: 50px;
  font-size: 20px;
  font-family: "Inter", sans-serif !important;
}
input,
input::placeholder {
  font-size: 16px;
}

.search-button {
  padding: 16px 16px;
  background-color: #2a5f81;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  height: 50px;
  width: 70px;
}

.generate-btn-wrapper {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.generate-btn {
  padding: 20px;
  border: 2px solid #2a5f81;
  color: #2a5f81;
  background-color: #fff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}

.generate-btn:hover {
  padding: 20px;
  border: 2px solid #2a5f81;
  color: #fff;
  background-color: #2a5f81;
  border-radius: 10px;
}

.search-button:hover {
  background-color: #2a5f81;
}

.container h3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* Limit content to 5 lines */
  -webkit-box-orient: vertical;
  margin: 0;
}

/* .container p {
    display: inline-block;
    max-width: 550px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
} */

.mt-20 {
  margin-top: 20px;
}

.intruction-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.intruction-gap {
  margin: 15px 0;
}

.container p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* Limit content to 5 lines */
  -webkit-box-orient: vertical;
  margin: 0;
  margin-top: 20px;
}

.container-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  min-height: 180px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  /* width: calc(50% - 10px); */
  transition: 0.3s;
  margin-bottom: 10px;
}

.container:hover {
  background-color: #6396b5;
}

.active {
  background-color: #2a5f81;
  color: #fff;
}

.container:hover h3 {
  color: #fff;
}

.container:hover p {
  color: #fff;
}

.accordion-wrapper {
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid lightgrey;
  padding: 20px;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* margin-top: 30px; */
}

.accordion-title-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  height: 30px;
  display: flex;
}

.accordion-title-arrow img {
  cursor: pointer;
}

.editor-wrapper {
  margin-top: 30px;
}

.e-toolbar-wrapper {
  display: none;
}

.e-rte-content {
  height: 250px !important;
}

.e-richtexteditor {
  height: 250px !important;
}

@media (max-width: 768px) {
  .container-grid {
    grid-template-columns: 1fr;
  }

  /* .container {
        width: 100%;
    } */
}

.editing-disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.editing-allowed {
  cursor: text;
}

.edit-outline-active {
  width: 1.2rem;
  height: 1.2rem;
  padding: 0.55rem;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #00000020;
}

.edit-outline {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
}
