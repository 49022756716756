.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px;
  background-color: #eef2fa;
  color: #000;
}

.left-section {
  /* flex-grow: 1; */
  justify-content: flex-start;
}

.left-section input[type="text"] {
  width: 80%;
  padding: 12px 8px;
  border-radius: 5px;
  border: none;
}

.right-section {
  display: flex;
  align-items: flex-end;
}

/* .login-info,
  .profile-info,
  .cart {
    margin-left: 20px;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
  }
   */
.profile-info img {
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.user_name {
  font-size: 14px;
}
.profile-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: -120px;
  left: -20px;
}

.profile-info:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .logo {
    mix-blend-mode: multiply;
    object-fit: contain;
    align-self: flex-start;
  }
}
