/* Container is not being used currently */

/* .container {
  max-width: 80rem;
  margin: 2rem auto;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
} */
.container {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1.8rem;
}

.essay-info {
  /* background-color: #2a5f81; */
  margin-bottom: 1.8rem;
  /* line-height: 0.5rem; */
}
.quick-text-generate-btn {
  cursor: pointer;
  margin-left: auto;
  padding: 20px;
  color: #2a5f81;
  background-color: #fff;
  border: 2px solid #2a5f81;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}
.section-title {
  font-weight: 600;
  margin-right: 0.2rem;
}

.section-description {
  font-size: 1rem;
}

.input {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  height: 50px;
  font-size: 20px;
  font-family: "Inter", sans-serif !important;
}

.input-section > .instruction {
  margin-bottom: 1.8rem;
}

.input-container {
  margin-bottom: 1.8rem;
  align-items: center;
  gap: 20px;
}
.inputLabel{
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: lightcoral;
  margin: 10px;
}

.input {
  width: 100%;
  display: inline-block;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}
.uploadIcon {
  color: #fff;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 5px;
  background-color: #2A5F81;
}
.uploadIconContainer {
  color: #fff;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  background-color: #2a5f81;
}
.translateContainer {
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 5px;
}

.options {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.option {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  /* Tailwind mr-4 */
  space-x-2: 0.5rem;
  /* Approximation for Tailwind space-x-2 */
}

/* .radio {
  cursor: pointer;
  color: #2A5F81;
  background-color: #2A5F81;
  font-size: 1rem;
 
} */

.radio {
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  background-color: #f0f0f0;
  border: 2px solid currentColor;
  border-radius: 50%;
}

.radio:checked {
  background-color: #2a5f81;
}

.label {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.label:hover {
  cursor: pointer;
}

.radio-text {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0 6px;
}

.error-message {
  color: red;
  font-weight: 500;
  font-size: 0.875rem;
  margin: -20px 0 0 0;
}

.label {
  /* font-size: 0.875rem; */
  /* Tailwind text-sm */
}

.quick-text-generate-btn {
  cursor: pointer;
  margin-left: auto;
  padding: 20px;
  color: #2a5f81;
  background-color: #fff;
  border: 2px solid #2a5f81;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}

.quick-text-generate-btn:hover {
  padding: 20px;
  border: 2px solid #2a5f81;
  color: #fff;
  background-color: #2a5f81;
  border-radius: 10px;
}

.quick-text-generate-btn:disabled {
  cursor: not-allowed;
}

.error-message {
}
.button {
  cursor: pointer;
}
.button {
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: calc(50% - 0.5rem);
  background-color: #2A5F81;
}

.button.outline {
  color: #2A5F81;
  border: 1px solid #2A5F81;
  background-color: transparent;
}

.text-area-section > .word-processor-label {
  border: 1px solid #e5e7eb;
  /* Close to Tailwind border color */
  padding: 0.5rem;
  /* Tailwind p-2 */
  margin-bottom: 0.5rem;
  /* Tailwind mb-2 */
}

.text-area {
  border: 1px solid #e5e7eb;
  /* Similar to Tailwind border color */
  padding: 0.5rem;
  /* Tailwind p-2 */
  height: 8rem;
  /* Tailwind h-32 */
  width: 100%;
}

.selectContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 20px;
  gap: 10px;
  flex-wrap: wrap;
}
/* Styling for dropdowns */
.dropdown {
  padding: 10px;
  font-size: 16px;
}

/* Styling for the submit button */
.submitBtn {
  padding: 13px 20px;
  border: 2px solid #2a5f81;
  font-size: 16px;
  border-radius: 10px;
  background-color: whitesmoke;
  color: #2a5f81;
  cursor: pointer;
}

.submitBtn:hover {
  padding: 13px 20px;
  border: 2px solid #2a5f81;
  color: #fff;
  background-color: #2a5f81;
  border-radius: 10px;
}
.swapBtn {
  padding: 10px 15px;
  color: white;
  border: none;
  cursor: pointer;

  border-radius: 10px;
  outline: none;
  height: 50px;
  font-size: 20px;
  font-family: "Inter", sans-serif !important;

}

.newButton {
  cursor: pointer;
  margin-left: auto;
  padding: 10px;
  color: #2a5f81;
  background-color: #fff;
  border: 2px solid #2a5f81;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}

.newButton:hover {
  padding: 10px;
  border: 2px solid #2a5f81;
  color: #fff;
  background-color: #2a5f81;
  border-radius: 10px;
}

@media (max-width: 640px) {

  .selectContainer .input-container {

    flex-direction:column;

  }
  .dropdown, .submitBtn, .input {
    width: 100%;
    margin-top: 10px;
  }
}

