/* Document sidebar styles */

.tabTrackContainer {
  display: flex;
}

.tabTrack {
  width: 2px;
  height: 25rem;
  position: relative;
  border-radius: 8px;
  background-color: #ccc;
}

.tabThumb {
  /* top: 8%; */
  /* top: 42%; */
  /* top: 75%; */
  left: -50%;
  width: 5px;
  height: 4rem;
  border-radius: 4px;
  position: absolute;
  background-color: black;
}

.tabTitleContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0 2rem 0 0 ; */
  justify-content: space-around;
  overflow: hidden;
}

.tabTitle:hover {
  color: #fff;
  background-color: #2a5f81;
}

.tabTitle {
  cursor: pointer;
  width: 8.5rem;
  height: 100%;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

/* From Files */

.nextButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}

.nextButton,
.generateButton {
  outline: none;
  display: flex;
  justify-content: center;
  border: 1px solid #000;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 4rem;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 2rem 1rem 2rem 0;
}

.generateButton {
  color: #fff;
  background-color: #2a5f81;
}

.paragraph {
  font-size: 1rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.uploadIconContainer {
  color: #fff;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  background-color: #2a5f81;
}

.uploadIcon {
  color: #fff;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 5px;
  /* background-color: #2A5F81; */
}

.inputField {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: flex-start;
}

.inputField {
  width: 100%;
}

.input,
.button {
  outline: none;
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid #000;
  padding: 0.8rem 1rem;
  /* margin-left: 0.5rem; */
}

.input {
  width: 100%;
  border: 1px solid #333;
  border-radius: 5px;
}

.button {
  cursor: pointer;
}

.infoContainer {
  margin: 2rem 0 0 0;
}

.errorText,
.successText {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.errorText {
  color: #ef4444;
}

.successText {
  color: #10b981;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

/* Document Generation */
/* .documentGeneration {} */

.documentTypeContainer {
  display: flex;
  gap: 1rem;
  margin: 3rem 0 0 0;
}

.selectDocumentType {
  width: 40%;
}

.documentInstructions {
  width: 60%;
}

.highlight {
  font-weight: 600;
}

.documentInstructionsText {
  margin: 0 0 1rem 0;
}

.instructionsInputContainer {
  margin: 2rem 0 0 0;
}

.instructionsLabel {
  font-weight: 600;
  font-size: 1rem;
  color: #000;
}

.instructionsInput {
  width: 100%;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 0.75rem;
}
