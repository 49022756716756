.sidebar {
  width: 100%;
  background-color: #fff;
  color: white;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.logo img {
  width: 11rem;
  /* Adjust the width of the logo as needed */
  /* margin: -1rem 0 -1.8rem 0; */
  /* Add some margin above the logo */
  margin: -3.6rem 0 -3.8rem 0;
}

.buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-weight: 500;
}

.buttons button {
  margin-bottom: 15px;
  /* Add margin between buttons */
  padding: 8px;
  border: none;
  background-color: #eef2fa;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  align-items: center;
  display: flex;
  gap: 10px;
  height: 65px;
}

.btn-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.buttons button img {
  width: 24px;
  height: 24px;
}

.sidebar-heading {
  margin-top: 20px;
  /* Add some margin between the logo and buttons */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.btn-sub-heading {
  font-size: 10px;
  font-weight: 400;
  /* color: #6D6D6D; */
}

.active-button {
  color: white !important;
  background-color: #2a5f81 !important;
}

.sidebar-btn-style {
  font-size: 18px;
  border-radius: 5px;
  width: 90%;
}

.buttons button:hover {
  background-color: #2a5f81;
  color: white;
}

.sidebar-btn-style:hover .btn-sub-heading {
  color: white;
}

.logout-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;
  margin: 0.5rem 0 0rem 0;
}

.logout-btn img {
  width: 1.2rem;
  height: 1.2rem;
}

.logout-btn .logout-btn-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.profile-info {
  margin-left: 10px;
  position: relative;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  color: #000;
}

.login-info {
  position: relative;
  cursor: pointer;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0;
  color: #000;
}

.avatar {
  width: 2.6rem;
  height: 2.6rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #2a5f81;
}

.user_name {
  color: #fff;
  font-weight: 600;
  font-size: 0.95rem;
}

.dropdown-buttons-container {
  position: relative;
  width: 90%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-left: 3px solid #2a5f81;
  margin-left: 0.2rem;
}

.dropdown-buttons-container::before {
  /* position: absolute;
  content: '';
  height: 5px;
  width: 2px;
  top: 0px; */
  position: absolute;
  content: "";
  top: -20px !important;
  left: -3px;
  height: 20px;
  background-color: #2a5f81;
  width: 3px;
}

.dropdown-buttons {
  width: 90%;
  border: none;
  display: flex;
  margin-left: 0.85rem;
  align-items: center !important;
  justify-content: flex-start !important;
  margin-right: auto;
  border-radius: 5px;
  text-align: start;
  /* justify-content: center; */
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  height: 45px !important;
  padding-left: 1rem !important;
}

.dropdown-buttons:last-child {
  margin-bottom: 10px !important;
}

.dropdown-buttons::before {
  left: 0px;
  position: absolute;
  content: "";
  height: 3px;
  background-color: #2a5f81;
  width: 0.9rem;
  /* 3px solid #2A5F81; */
}

.bottom_container {
  margin-top: 2rem;
}
.close_sidebar_btn {
  display: none;
}

@media (max-width: 768px) {
  .close_sidebar_btn {
    display: block;
    border: none;
    outline: none;
    background: none;
  }
  .logo {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 1rem;
  }
}
