.screen_container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  /* Close to Tailwind bg-gray-100 */
}

.form_container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 32rem;
  margin: 0 0 2rem 0;
  overflow: hidden !important;
}

.logo_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: -1.75rem;
  justify-content: space-between;
}

.signup_logo {
  height: 10rem;
  width: 10rem;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.form_group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2ch;
}

.form_group_secondary {
  display: flex;
  column-gap: 2ch;
  margin: 0 0 0.75rem 0;
  justify-content: space-between;
}

.input_secondary_container {
  /* width: 60%; */
  width: 100%;
}

.generateBtn {
  width: 40%;
  height: 2.6rem;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2a5f81;
}

.generateBtn:disabled {
  /* cursor: not-allowed; */
  background-color: rgba(8, 75, 117, 0.6);
}

.form_group_bottom {
  margin: -0.75rem 0 0 0;
}

.input_secondary {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
}

.input_secondary:disabled {
  /* cursor: not-allowed; */
  background-color: #eee;
}

.name_fields {
  display: flex;
}

.input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
}

.input:disabled {
  /* cursor: not-allowed; */
  background-color: #eee;
}

.error_message {
  color: red;
  font-size: 0.75rem;
  margin: 0.5rem 0 0 0.2rem;
  font-weight: 500;
}


.half_width {
  /* flex: 1; */
}

.full_width {
  /* overflow: hidden; */
  /* width: 97%; */
}

.button_group {
  display: flex;
  width: 100%;
  margin: 2rem 0 0 0;
  align-items: center;
  justify-content: space-between;
}

.button {
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: calc(50% - 0.5rem);
  background-color: #2A5F81;
  /* border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #000;
  width: calc(50% - 0.5rem);
  color: white !important;
  background-color: #2A5F81 !important; */
}

.button.outline {
  background-color: transparent;
  border: 1px solid #2A5F81;
  /* Tailwind blue-500 */
  color: #2A5F81;
}

.loader_button {
  width: 1.25rem;
  height: 1.25rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader_spin {
  animation-name: spin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.help {
  cursor: pointer;
  font-weight: 500;
  font-size: 0.85rem;
}

.help_underline {
  text-decoration: underline;
}
.passwordInput {
  border: none;
  outline: none;
  background-color: #e8f0fe;
}
.input {
  /* width: 100%; */
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  background-color: #e8f0fe;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
input:focus {
  background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 768px) {
  .form_container {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80vw;
    margin: 0 0 2rem 0;
    overflow: hidden !important;
  }
  .button_group {
    display: flex;
    gap: 2rem;
  }
  .input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
  }
}

.check_box_div{
  display: flex;
  overflow-x: hidden;
  margin-top: 5px;

}

.check_box_lable{
  margin-left: 1px;
  margin-top: 0px;
}
.check_box{
  width: 15px;
  height: auto;
  margin: 2px 4px 0 2px;

}
