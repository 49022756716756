
  .success_banner {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; 
}

.success_banner_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .75rem 2.5rem;
    border-radius: 5px;
    margin-top: 1rem;
    margin-left: 10rem;
    width: 80%;
}

.container {
  background-color: rgb(228, 175, 28);
  padding: 16px; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white; 
  border-radius: 5px;
  width: 100%;
}

.innerContainer {
  display: flex;
  align-items: center;
  gap: 8px; 
 
}

.icon {
  height: 24px; 
  width: 24px;
  background-color: red;
  padding: 4px;
  border-radius: 50%;
}

.buttonIcon {
  height: 16px; 
  width: 16px; 
}

.ghostButton {
  background: transparent;
  border: none;
  color: inherit;
}
