/* .loader-wrapper{
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
}
.loader {
    position: absolute;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    z-index: 10;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 12px 0 0 0;
}

.loader-info {
  margin-top: 0.5rem;
  font-size: 0.85rem;
}

@keyframes spin-pause {
  0%,
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75%,
  100% {
    transform: rotate(180deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 10);
  padding: 0.75rem 2.5rem;
  border-radius: 5px;
  border: 2px solid #333;
}

.loader-hourglass {
  height: 4rem;
  width: 4rem;
  margin-right: 1rem;
  animation: spin-pause 4s linear infinite;
}

/* .loader::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: #fff;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
} */
@media (max-width: 500px) {
  .loader {
    width: 60%;
    height: 6%;
    align-self: center;
    justify-self: center;
  }
}
