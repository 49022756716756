.main_container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}

.loader_text {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 12px 0 0 0;
}

.loader_info {
  margin-top: 0.5rem;
  font-size: 0.85rem;
}

.inner_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 10);
 padding: 2rem;
  border-radius: 5px;
  border: 2px solid #333;
  height: 18rem;
  width: 26rem;
}

.forms {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.label {
  font-weight: 600;
  font-size: 1rem;
  margin: 0 0 .5rem 0;
}

.input {
  outline: none;
  border-radius: 5px;
  border: 1px solid #333;
  padding: .75rem .5rem;
  margin: 0 0 1rem 0;
}

.btn {
  cursor: pointer;
  margin-top: 3rem;
  outline: none;
  border: none;
  color: white;
  padding: .75rem .5rem;
  border-radius: 5px;
  font-size: .85rem;
  font-weight: 600;
  background-color: #2A5F81;
}


.closeBtnContainer {
  display: flex;
  width: 100%;
 justify-content: flex-end;
}

.closeBtn {
  margin: 0 0 .5rem 0;
}

.textLabel {
  display: none;
}

.textInput {
  display: none;
}

.loader_hourglass {
  height: 4rem;
  width: 4rem;
  margin-right: 1rem;
  animation: spin-pause 4s linear infinite;
}



@keyframes spin-pause {
  0%, 25% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  75%, 100% {
      transform: rotate(180deg);
  }
}


@-webkit-keyframes spin {
  from {
      -webkit-transform: rotate(0deg);
  }

  to {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

