.screen_container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  /* Close to Tailwind bg-gray-100 */
}

.form_container {
  /* background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 24rem;
  margin: 0 0 2rem 0; */
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 32rem;
  margin: 0 0 2rem 0;
  overflow: hidden !important;
}

.logo_container {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: -1.75rem;
  justify-content: space-between;
  margin: 0 0 2rem 0;
}

.signup_logo {
  height: 6rem;
  width: 10rem;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.form_group {
  display: flex;
  width: 100%;
}

.name_fields {
  display: flex;
}

.passwordInput {
  border: none;
  outline: none;
  background-color: #e8f0fe;
}
.input {
  /* width: 100%; */
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  background-color: #e8f0fe;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
input:focus {
  background-color: rgba(0, 0, 0, 0);
}
.error_message {
  color: red;
  font-size: 0.75rem;
  margin: -0.75rem 0 0 0.2rem;
  font-weight: 500;
}

.button_group {
  display: flex;
  margin: 2rem 0 0 0;
  align-items: center;
  justify-content: space-between;
}

.button {
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: calc(50% - 0.5rem);
  background-color: #2a5f81;
}

.button.outline {
  color: #2a5f81;
  border: 1px solid #2a5f81;
  background-color: transparent;
}

.loader_button {
  width: 1.25rem;
  height: 1.25rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader_spin {
  animation-name: spin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.help {
  cursor: pointer;
  font-weight: 500;
  font-size: 0.85rem;
}

.help_underline {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .form_container {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 80vw;
    margin: 0 0 2rem 0;
    overflow: hidden !important;
  }
  .logo_container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: -1.75rem;
    justify-content: center;
    margin: 0 0 2rem 0;
  }
  .input {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
  }
}
