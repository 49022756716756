/* Transform to Document */

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  /* p-6 */
}

.title {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

/* Styles for the content */

.tabContainer {
  display: flex;
  padding: 2rem 0;
}

.tabContent {
  padding: 0 3rem;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 640px) {
  .tabContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
@media (max-width: 400px) {
  .tabContent {
    padding: 0 0.5rem;
  }
}
