.container {
  width: 80%;
  margin: 3rem auto;
}

.infoContainer {
  display: flex;
  align-items: flex-end;
  margin: 0 0 4rem 0;
  justify-content: center;
  flex-wrap: wrap;
}

.heading {
  font-weight: 600;
  margin: 0;
  font-size: xx-large;
  text-align: center;
  margin-bottom: 20px;
}

.title {
  font-weight: 600;
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  font-size: 40px; /* Default font size for the title */
}

.title::-webkit-scrollbar {
  display: none;
}

.downloadLinkWrapper {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.downloadBTN {
  border: 1px solid rgb(207, 206, 206);
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px;
  /* outline: none; */
  font-size: 20px;
  align-items: center;
  background-color: #eef2fa;
  font-weight: 600;
  cursor: pointer;
}
.dropdown_menu {
  -webkit-box-shadow: 0px 0px 7px 4px rgba(217, 214, 217, 1);
  -moz-box-shadow: 0px 0px 7px 4px rgba(217, 214, 217, 1);
  box-shadow: 0px 0px 7px 4px rgb(240, 239, 240);
}
.dropdown_item {
  list-style: none;
  align-items: center;
}
.dropdown_item::before {
  /* /* content: "\2192";  */
  margin-right: 10px;
}
.infoContainerRight {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: center;
  /* border: 1px solid;
  border-color: #2a5f81;
  border-radius: 4px; */
}
.dropdown-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.download {
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.share {
  cursor: pointer;
}

.quizContentContainer {
  margin: 0 0 3rem 0;
}

.questionContainer {
  margin: 0 0 1rem 0;
}

.questionNumber {
  font-weight: 600;
  margin: 0 0.5rem 0 0;
}

.questionText {
  font-size: 1rem;
  font-weight: 500;
}

@media (min-width: 1300px) {
  .title {
    font-size: 50px; /* Larger font size for full-size laptop screens */
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .title {
    font-size: 40px; /* Adjust font size for medium screens */
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .heading {
    margin-bottom: 20px;
  }

  .title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .container {
    margin: -20px auto;
  }

  .infoContainer {
    width: 120%;
    margin-left: -10%;
    margin-right: -10%;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 20px;
  }

  .infoContainer,
  .quizContentContainer {
    width: 130%;
    margin-left: -15%;
    margin-right: -15%;
  }
}
