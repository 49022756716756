  .loader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}


.loader_text {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 12px 0 0 0;
}

.loader_info {
    margin-top: 0.5rem;
    font-size: 0.85rem;
}

@keyframes spin-pause {
    0%, 25% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75%, 100% {
        transform: rotate(180deg);
    }
}


@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 10);
    padding: .75rem 2.5rem;
    border-radius: 5px;
    border: 2px solid #333;
}

.loader_hourglass {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
    animation: spin-pause 4s linear infinite;
}

