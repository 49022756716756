.documentGeneration {
  width: 110%;
  margin-left: -5%;
}
.documentTypeContainer {
  display: flex;
  gap: 1rem;
  margin: 2rem 0 0 0;
  height: 100%;
}

.selectDocumentType {
  gap: 4ch;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.selectDocumentGroup {
  display: flex;
  flex-direction: column;
}

.selectDocumentLabel {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.highlight {
  font-weight: 600;
}

.documentInstructionsText {
  margin: 0 0 1rem 0;
}

.instructionsInputContainer {
  margin: 2rem 0 0 0;
}

.instructionsLabel {
  font-weight: 600;
  font-size: 1rem;
  color: #000;
}

.instructionsInput {
  width: 100%;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 0.75rem;
}
.checkboxAnimate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  margin: auto;
  padding: 0;
  margin-top: 22px;
}

.checkboxAnimate label {
  position: relative;
  cursor: pointer;
}

.input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  display: flex;
  flex-direction: row;
}

.inputCheck {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 2px solid #ccc;
  position: relative;
  top: 6px;
  margin-right: 7px;
  transition: 0.4s;
}

.inputCheck::before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 6px;
  border-bottom: 4px solid #fff;
  border-left: 4px solid #fff;
  transform: scale(0) rotate(-45deg);
  position: absolute;
  top: 6px;
  left: 4px;
  transition: 0.4s;
}

.input:checked ~ .inputCheck {
  background-color: #2a5f81;
  border-color: #2a5f81;
  animation-name: inputAnimate;
  animation-duration: 0.7s;
}

.input:checked ~ .inputCheck::before {
  transform: scale(1) rotate(-45deg);
  animation-name: inputCheck;
  animation-duration: 0.2s;
  animation-delay: 0.3s;
}

@keyframes inputAnimate {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.3, 0.7);
  }
  55% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2, 0.8);
  }
  80% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1, 0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes inputCheck {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}

/* .btnContainer {
  display: flex;
  height: 100%;
  align-items: flex-end;
}

.btnInnerContainer {
  display: flex;
  align-items: center;
}

.btnText {
  margin: 0 0 0 1rem;
  font-weight: 500;
  font-size: 0.95rem;
}

.btn {
  display: flex;

  background-color: lightgray;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  height: 2rem;
  position: relative;
  width: 5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.btn:hover {
  background-color: lightgray;
}

.btn:focus,
.btn:active {
  outline: none;
}

.btn::-moz-focus-inner {
  border: 0;
}

.pin {
  background-color: white;
  border-radius: 98px;
  height: 1.6rem;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 2em;
  transition: left ease 0.5s;
}

.btn.on {
  background-color: #2a5f81;
}

.btn.on .pin {
  left: 1.8rem;
} */

@media (max-width: 768px) {
  .documentTypeContainer {
    display: flex;
    flex-direction: column;
  }
  .selectDocumentType {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2ch;
  }
}

/* /////////////////////////////////////////////////// */
