/* @import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-layouts/styles/material.css"; */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

.e-documenteditorribbon .e-file-menu-container {
  display: none !important;
}
