.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap:20px
}

.X-btn {
  color: red;
  font-size: 15vw;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code {
  width: 70vw;
  height: 70vw;
}

@media (min-width: 768px) {
  .qr-code {
    width: 35vw;
    height: 35vw;
  }

  .X-btn {
    font-size: 5vw;
  }
}

@media (min-width: 1200px) {
  .qr-code {
    width: 20vw;
    height: 20vw;
  }

  .X-btn {
    font-size: 3vw;
  }
}
