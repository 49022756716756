/* Container is not being used currently */

/* .container {
  max-width: 80rem;
  margin: 2rem auto;
  padding: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
} */

.title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 1.8rem;
}

.essay-info {
  /* background-color: #2a5f81; */
  margin-bottom: 1.8rem;
  /* line-height: 0.5rem; */
}

.section-title {
  font-weight: 600;
  margin-right: 0.2rem;
}

.section-description {
  font-size: 1rem;
}

.input {
  flex-grow: 1;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  height: 50px;
  font-size: 20px;
  font-family: "Inter", sans-serif !important;
}

.input-section > .instruction {
  margin-bottom: 1.8rem;
}

.input-container {
  margin-bottom: 1.8rem;
  display: flex;
  align-items: center;
  gap: 20px;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
}

.options {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.option {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  /* Tailwind mr-4 */
  space-x-2: 0.5rem;
  /* Approximation for Tailwind space-x-2 */
}

/* .radio {
  cursor: pointer;
  color: #2A5F81;
  background-color: #2A5F81;
  font-size: 1rem;
 
} */

.radio {
  width: 20px;
  height: 20px;
  cursor: pointer;
  appearance: none;
  background-color: #f0f0f0;
  border: 2px solid currentColor;
  border-radius: 50%;
}

.radio:checked {
  background-color: #2a5f81;
}

.label {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.label:hover {
  cursor: pointer;
}

.radio-text {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 0 6px;
}

.error-message {
  color: red;
  font-weight: 500;
  font-size: 0.875rem;
  margin: -20px 0 0 0;
}

.label {
  /* font-size: 0.875rem; */
  /* Tailwind text-sm */
}

.quick-text-generate-btn {
  cursor: pointer;
  margin-left: auto;
  padding: 20px;
  color: #2a5f81;
  background-color: #fff;
  border: 2px solid #2a5f81;
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
}

.quick-text-generate-btn:hover {
  padding: 20px;
  border: 2px solid #2a5f81;
  color: #fff;
  background-color: #2a5f81;
  border-radius: 10px;
}

.quick-text-generate-btn:disabled {
  cursor: not-allowed;
}

.error-message {
}

.text-area-section > .word-processor-label {
  border: 1px solid #e5e7eb;
  /* Close to Tailwind border color */
  padding: 0.5rem;
  /* Tailwind p-2 */
  margin-bottom: 0.5rem;
  /* Tailwind mb-2 */
}

.text-area {
  border: 1px solid #e5e7eb;
  /* Similar to Tailwind border color */
  padding: 0.5rem;
  /* Tailwind p-2 */
  height: 8rem;
  /* Tailwind h-32 */
  width: 100%;
}
@media (max-width: 640px) {
  .options {
    flex-direction: column;
    align-items: stretch; /* Adjust alignment as needed */
  }

  .option {
    margin-right: 0; /* Remove right margin on small screens */
    margin-bottom: 1rem; /* Add bottom margin for spacing */
  }
  .quick-text-generate-btn {
    width: 80vw; /* Make the button take up 80% of the viewport width */
    margin-left: auto; /* Center the button */
    margin-right: auto; /* Center the button */
  }
  .search-input {
    width: 80%;
  }
}

@media (max-width: 430px) {
  .essay-info {
    line-height: 2ch;
  }
}
