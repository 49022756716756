.banner {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 2.5rem;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  color: black;
  background-color: white;
  border: 1px solid #ccc;
  /* assuming a light grey border */
  border-radius: 12px;
  padding: 24px;
  width: 400px;
}

.closeButtonContainer {
  position: absolute;
  top: 12px;
  right: 12px;
}

.closeIcon {
  height: 16px;
  width: 16px;
  margin-top: -8px;
  margin-bottom: 20px;
}

.textSmall {
  font-size: 0.875rem;
  /* equivalent to text-sm in Tailwind */
}

.textSmallBold {
  font-size: 0.875rem;
  font-weight: bold;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 10px;
}

.ghostButton,
.outlineButton,
.standardButton {
  border: none;
  background: transparent;
  cursor: pointer;
  height: 2rem;
  width: 4rem;
  border-radius: 5px;
}

.outlineButton {
  border: 1px solid #ccc;

  /* simple outline */
}

.standardButton {
  color: #fff;
  background-color: black;
}
