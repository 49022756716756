.container {
  width: 100%;
  margin: auto;
  /* margin-left: auto; */
  /* margin-right: auto; */
  padding: 1rem 1.5rem;
}

.title {
  width: 100%;
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  margin: 0 0 3rem 0;
}

.table {
  width: 100%;
  margin: 2rem 0;
}
.mail {
  color: #2a5f81;
}
.info_text {
  font-size: small;
}

.column_container {
  color: #fff;
  padding: 1rem;
  display: grid;
  border: 2px solid #333;
  border-radius: 5px 5px 0 0;
  background-color: #2a5f81;
  grid-template-columns: repeat(3, 1fr);
}

.row_container {
  padding: 1rem;
  display: grid;
  margin: -2px 0 0 0;
  border-radius: 0 0px 5px 5px;
  border: 2px solid #333;
  grid-template-columns: repeat(3, 1fr);
}

.column {
  display: flex;
  font-weight: 600;
  font-size: 0.95rem;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  font-weight: 400;
  font-size: 0.95rem;
  align-items: center;
  justify-content: center;
}

.failed {
  color: red !important;
}

.progress {
  color: green !important;
}

.downlink_link {
  margin: 0 2rem 0 0;
  align-items: center;
  justify-content: center;
}

.refreshIcon {
  width: 1.2rem;
  height: 1.2rem;
  cursor: pointer;
}

.row_container {
}

.link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 500px) {
  .container {
    /* padding: 1rem; Adjust padding as needed */
    width: 120%; /* Make the container take full width */
    margin: 0 auto; /* Center the container */
    margin-left: -10%;
  }

  .table {
    overflow-x: auto;
    align-items: center;
  }

  .column_container {
    flex-direction: column;
    align-items: flex-start;
  }

  .column {
    font-size: 0.8rem; /* Reduce font size */
    margin-bottom: 0.5rem;
  }

  .row_container {
    flex-direction: row;
    align-items: center; /* Align items vertically in the center */
    justify-content: center;
  }

  .row {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: center;
  }

  .link {
    display: block;
    text-align: center;
    /* margin-top: 1rem; Adjust margin as needed */
  }

  .nextButtonContainer {
    margin-top: 1.5em; /* Adjust margin as needed */
  }
}
