.container {
  display: flex;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.header {
  text-align: center;
}

.title {
  margin: 0;
}

.description {
  margin: .5rem 0 0 0;
}