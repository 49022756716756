.container {
  padding: 1.5rem;
}

/* #2A5F81 */

.header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title {
  color: #2a5f81;
}

.content_container {
  padding: 1rem;
}

.content {
  gap: 2ch;
  display: flex;
  margin: 0 0 2rem 0;
}

.img_container {
}

.img {
  height: 3rem;
  width: 3rem;
}

.text_content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.content_title {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 0 0.2rem 0;
}

.content_description {
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 1rem 0;
}

.content_actionTitle {
  color: #2a5f81;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: fit-content;
  align-self: center;
}
.content_actionTitle::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
/* .content_actionTitle:hover {
  right: 0;
  opacity: 1;
  transition: 0.2s ease-in;
  text-decoration: underline;
} */
.content_actionTitle:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 400px) {
  .title {
    text-align: center;
  }
  .content_description {
    font-size: 1rem;
  }
  .content {
    flex-direction: column;
  }
  .img_container {
    margin: 0 auto;
  }
  .content_title {
    text-align: center;
  }
  .text_content {
    text-align: center;
  }
}
